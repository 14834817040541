<template>
  <v-form>
    <v-row>

      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Item Group Code"
          v-model="record.ItmsGrpCod"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Item Group Name"
          v-model="record.ItmsGrpNam"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-tabs
          background-color="accent"
          dark
        >
          <v-tab> Accounting</v-tab>
          <v-tab> General</v-tab>

          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="accounts"
                >
                  <template v-slot:item.Code="{ item }">
                    <v-text-field
                      style="width: 200px"
                      single-line
                      outlined
                      dense
                      v-model="item.Code"
                      :append-icon="'mdi-airballoon'"
                      @click:append="openModal(item)"
                    ></v-text-field>
                  </template>

                  <template v-slot:item.AcctName="{ item }">
                    <span>{{item.AcctName}}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item> general </v-tab-item>
        </v-tabs>
      </v-col>

      <v-col cols="12">
        <v-btn
          :loading="loader"
          v-if="createStatus == true"
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
        <v-btn
          :loading="loader"
          v-else
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon>Update
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-dialog
        v-model="dialog"
        max-width="800px"
      >
        <v-card>
          <v-toolbar
            color="accent"
            dense
            dark
          >
            <v-toolbar-title>List of Accounts</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click.native="dialog = false"
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-data-table
                hide-default-footer
                :headers="glHeaders"
                :items="glAccounts"
                show-select
                :single-select="singleSelect"
                v-model="selected"
                @item-selected="clickedItem"
              >
              </v-data-table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    createStatus: {
      type: Boolean,
    },
  },
  data: () => ({
    singleSelect: true,
    selected: [],
    loader: false,
    dialog: false,
    record: {},
    accounts: [],
    glAccounts: [],
    selectedId: null,
    headers: [
      { text: "Type of Account", value: "Description" },
      { text: "Account Code", value: "Code" },
      { text: "Account Name", value: "AcctName" },
    ],
    glHeaders: [
      { text: "Account Number", value: "AcctCode" },
      { text: "Account Name", value: "AcctName" },
      { text: "Balance", value: "CurrTotal" },
    ],
    date: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      if (this.createStatus == false) {
        this.accounts = val.itb1;
      }
    },
    setDate(date) {
      this.record.date = date;
    },
    openModal(item) {
      this.dialog = true;
      this.selectedId = item.id;
    },
    clickedItem($event) {
      const account = $event.item;
      const id = this.selectedId;
      const activeRecord = this.accounts.find((record) => {
        return record.id === id;
      });

      const pos = this.accounts.indexOf(activeRecord);
      if (pos === -1) {
        return;
      }
      activeRecord.Code = account.AcctCode;
      activeRecord.AcctCode = account.id;
      activeRecord.AcctName = account.AcctName;
      this.accounts[pos] = activeRecord;
      this.selectedId = null;
      this.selected = [];
      this.dialog = false;
    },
    sendData() {
      let data = this.record;
      data.document_lines = this.accounts;
      this.loader = true;
      this.$emit("data", data);
    },
    getAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gl_account_determination`)
        .then((res) => {
          if (self.createStatus == true) {
            self.accounts = res.ResponseData.acp10;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getActiveGLAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then((res) => {
          self.glAccounts = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getAccounts();
    this.getActiveGLAccounts();
  },
};
</script>